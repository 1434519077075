<template>
  <section class="py-xl sm:py-3xl px-sm sm:px-md bg-white" v-if="cards?.length > 0">
    <div class="max-w-[1536px] mx-auto">
      <Heading v-if="header" size="md" class="text-charcoal-default text-center mb-sm md:mb-md">{{ header }}</Heading>
      <Text v-if="intro" size="lg" class="text-center mx-auto mb-xs w-full md:w-3/4">{{ intro }}</Text>
      <Stack
        class="mt-md flex-wrap"
        direction="col"
        :gap="cards.length < 3 ? '2xl' : 'xs'"
        :justify="cards.length < 3 ? 'center' : 'start'"
        :breakpoints="{
          direction: {
            sm: 'row',
          },
        }"
      >
        <Stack
          direction="col"
          :breakpoints="{
            direction: {
              md: orientation === 'landscape' && cards.length < 3 ? 'row' : 'col',
            },
          }"
          justify="between"
          :class="[
            'w-full',
            cards.length < 3 && orientation === 'landscape' ? 'sm:w-[45%]' : 'sm:w-[31.55%]',
            cards.length >= 3 && 'mx-auto',
          ]"
          v-for="card in cards"
          :key="card.title"
        >
          <NuxtImg
            format="avif, webp, jpeg"
            v-if="card.image"
            :src="card.image"
            :class="[
              'object-cover w-full sm:min-h-[250px] lg:h-[350px] mt-xs',
              cards.length < 3 && orientation === 'landscape' && 'max-w-[50%]',
            ]"
            :alt="card.title"
          />

          <Stack direction="col" justify="between" class="text-charcoal-default w-full">
            <section>
              <Text size="lg" weight="semi" class="mb-2xs"> {{ card.title }} </Text>
              <Text size="md"> {{ card.paragraph }}</Text>
              <div v-if="card.description" v-html="card.description" class="rich-text" />
            </section>

            <NuxtLink
              v-if="card.link.href && card.buttontext"
              :to="card.link.href"
              :class="[(orientation !== 'landscape' || cards.length > 2) && 'mx-auto']"
            >
              <Button class="mt-2xs" size="sm" variant="secondary"> {{ card.buttontext }} </Button>
            </NuxtLink>
          </Stack>
        </Stack>
      </Stack>

      <NuxtLink :to="link.href" v-if="link.href">
        <Button class="mx-auto mt-md" size="md" variant="secondary"> {{ link.text }} </Button>
      </NuxtLink>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ContainerItem, Page } from "@bloomreach/spa-sdk";
import { useEditorialCards } from "../../composables/UseContent/useEditorialCards";

const props = defineProps<{ component: ContainerItem; page: Page }>();
const { component, page } = toRefs(props);

const editorialCards = computed(() => useEditorialCards(page.value, component.value));

const header = computed(() => editorialCards.value.header);
const intro = computed(() => editorialCards.value.intro);
const cards = computed(() => editorialCards.value.cards);
const link = computed(() => editorialCards.value.link);
const orientation = computed(() => editorialCards.value.orientation);
</script>
